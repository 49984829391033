interface Vet {
    vet_id: number,
    user_id: number,
    name: string
    email: string,
    password: string,
    confirmation: string,
    type: string,
    phone: string,
    specialty: string,
    account_token: string
}

interface vetToken {
    vet_id: number,
    account_token: string
}

interface VetAurinkoProfile {
    vet_id: number,
    month_nr: number,
    month: string,
    aurinko_profile_id: number
}


interface WorkingInterval {
    start: string,
    end: string
}

interface DaySchedule {
    dayOfWeek: string,
    workingIntervals: WorkingInterval[]
}

interface AvailabilityConfig {
    id: number,
    name: string,
    durationMinutes: number,
    availabilityStep: number,
    timeAvailableFor: string,
    subject: string,
    description: string,
    location: string,
    workHours:
    {
        timezone: string,
        daySchedules: DaySchedule[],
    },
    context: string,
    startConference: boolean
}

interface createVetAvailabilityProfileParams {
    vet_id: number,
    id: number,
    name: string,
    durationMinutes: number,
    availabilityStep: number,
    timeAvailableFor: string,
    subject: string,
    description: string,
    location: string,
    workHours:
    {
        timezone: string,
        daySchedules: DaySchedule[],
    },
    context: string,
    startConference: boolean
}

interface IniVetAppointmentsParams {
    vetId: number,
    language: string
}

interface VetAppointment {
    appointment_id: number,
    schedule: string,
    customer_user_id: number,
    customer_name: string,
    pet_id: number,
    pet_name: string,
    vet_user_id: number,
    description: string,
    price: number,
    customer_pin: number,
    vet_pin: number,
    appointment_status: string,
    status_date: string
}

const vetResetState = () => ({
    editedVetData: {
        vet_id: 0,
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        type: 'vet',
        phone: '',
        specialty: '',
        account_token: '',
    },
    aurinkoProfiles: [] as VetAurinkoProfile[],
    editingVet: false,
    vetMode: 'availabilityconfig',
    hasAssociatedCalendar: false,
    vetRecord: [] as VetAppointment [],
    availableVetsUserIDs: [] as number []
});

export const editedVet = {
    state: vetResetState(),

    getters: {

        getEditedVetData(state: any): Vet {
            return (state.editedVetData)
        },
        getAurinkoProfiles(state: any): Vet {
            return (state.aurinko_profiles)
        },
        getEditingVet(state: any): boolean {
            return (state.editingVet)
        },
        getVetMode(state: any): string {
            return (state.vetMode)
        },
        getHasAssociatedCalendar(state: any): boolean {
            return (state.hasAssociatedCalendar)
        },
        getVetRecord(state: any): VetAppointment[] {
            return (state.vetRecord)
        },
        getAvailableVetsUserIDs(state: any): number[] {
            return (state.availableVetsUserIDs)
        }
    },
    mutations: {

        setEditedUserData(state: any, editedUserData: any) {
            state.editedVetData.user_id = editedUserData.user_id;
            state.editedVetData.email = editedUserData.email;
            state.editedVetData.name = editedUserData.name;
            state.editedVetData.vet_id = editedUserData.vet_id;
            state.editedVetData.phone = editedUserData.phone;
            state.editedVetData.account_token = editedUserData.account_token;
        },

        setEditedVetData(state: any, editedVetData: any) {
            state.editedVetData = editedVetData
        },

        setAurinkoProfiles(state: any, aurinkoProfiles: VetAurinkoProfile[]) {
            state.aurinko_profiles = aurinkoProfiles
        },
        setEditingVet(state: any, editingVet: boolean) {
            state.editingVet = editingVet
        },
        setVetMode(state: any, newVetMode: string) {
            state.vetMode = newVetMode
        },
        setHasAssociatedCalendar(state: any, hasAssociatedCalendar: boolean) {
            state.hasAssociatedCalendar = hasAssociatedCalendar
        },
        setVetRecord(state: any, vetRecord: VetAppointment[]) {
            console.log("setVetRecord");
            console.log(vetRecord);
            console.log(typeof (vetRecord));
            state.vetRecord = [];
            vetRecord.forEach((vetrecord: any) => {
                console.log(vetrecord);
                state.vetRecord.push(vetrecord)
            });
        },
        vetResetStateMut(state: any) {
            Object.assign(state, vetResetState())
        },
        setAvailableVetsUserIDs(state: any, availVetsUserIDs: number[]) {
            console.log("setAvailableVetsUserIDs");
            console.log(availVetsUserIDs);
            console.log(typeof (availVetsUserIDs));
            state.availableVetsUserIDs = [];
            availVetsUserIDs.forEach((vetUserID: any) => {
                console.log(vetUserID);
                state.availableVetsUserIDs.push(vetUserID)
            });
        },
    },
    actions: {

        async iniEditedVet(state: any, vetEmail: string) {

            console.log("iniEditedVet")

            try {
                const response = await fetch(process.env.BASE_URL + `api/users/vet/byemail/${vetEmail}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch vet data');
                }

                const vetData = await response.json();
                console.log(vetData);
                console.log(vetData.user_id);
                state.commit('setEditedVetData', vetData);
                console.log(vetData.email);
                 
                // Only call fetchVetAurinkoProfiles if the vet has an account token / an associated calendar 
                if(vetData.account_token === "" || vetData.account_token === null)
                {
                    // Call fetchVetAurinkoProfiles with vetId
                    state.commit('setHasAssociatedCalendar', false);
                }
                else
                {
                    // Call fetchVetAurinkoProfiles with vetId
                    state.commit('setHasAssociatedCalendar', true);
                    await state.dispatch('fetchVetAurinkoProfiles', vetData.vet_id);
                }

                return vetData; // Resolve the promise with the fetched vet data

            } catch (error) {
                console.error('Error fetching vet data:', error);
                throw error; // Re-throw the error to be caught by the caller
            }

        },

        async fetchVetAurinkoProfiles(state: any, vetId: string) {

            console.log("fetchVetAurinkoProfiles");

            try {
                const response = await fetch(process.env.BASE_URL + `api/vets/aurinkoprofiles/${vetId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                });
        
                if (!response.ok) {
                    throw new Error('Failed to fetch aurinko profiles');
                }
        
                const aurinkoProfiles = await response.json();
                state.commit('setAurinkoProfiles', aurinkoProfiles);
                return aurinkoProfiles;
            } catch (error) {
                throw error;
            }
        },

        async iniVetDetailing(state: any, vetData: any) {

            console.log("iniVetDetailing")

            console.log(vetData);
            console.log(vetData.user_id);
            state.commit('setEditedUserData', vetData)
        },


        iniVetEditing(state: any) {

            state.commit('setEditingVet', true);
        },

        endVetEditing(state: any) {

            state.commit('setEditingVet', false);
        },

        async editVet(state: any, editedVetData: Vet) {
            console.log(JSON.stringify(editedVetData))
            const response = await fetch(process.env.BASE_URL + `api/users/vet/` + editedVetData.user_id.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(editedVetData),
            })

            var editedVetId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedVetId = resjson.user_id

            } else {
                throw new Error('error_updating_vet')
            }
            return (editedVetId)
        },

        async updateVetToken(state: any, vetTokenData: vetToken) {
            console.log(JSON.stringify(vetTokenData))
            const response = await fetch(process.env.BASE_URL + `api/vets/token/` + vetTokenData.vet_id.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(vetTokenData),
            })

            var editedVetId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedVetId = resjson.vet_id

            } else {
                throw new Error('error_updating_token')
            }
            return (editedVetId)
        },

        async removeVetCalendarAssociation(state: any, vetId: number) {
            console.log(vetId)
            const response = await fetch(process.env.BASE_URL + `api/vets/removecalendarassociation/` + vetId.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
            })

            var editedVetId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedVetId = resjson.vet_id

            } else {
                throw new Error('error_removing_token')
            }
            return (editedVetId)
        },

        

        iniVetAvailabilityConfig(state: any) {
            state.commit('setVetMode', "availabilityconfig");
        },

        iniVetAvailabilitySlots(state: any) {
            state.commit('setVetMode', "availabilityslots");
        },

        iniVetCustomers(state: any) {
            state.commit('setVetMode', "customers");
        },
        iniVetAppointments(state: any) {
            state.commit('setVetMode', "appointments");
        },
        async iniVetRecord(state : any, params : IniVetAppointmentsParams) {
            console.log("iniVetRecord");
            console.log(params.vetId);
            console.log(params.language);
            console.log(process.env.BASE_URL + `api/vets/record/` + params.vetId.toString( ) + '/' + params.language);

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/vets/record/` + params.vetId.toString( ) + '/' + params.language,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((vet_record_data) => {
                        console.log(vet_record_data);
                        console.log(typeof (vet_record_data));
                        state.commit('setVetRecord', vet_record_data)
                        resolve(vet_record_data)
                    })
                    .catch(err => reject(err))
            }))
        },
        async fetchAvailableVetsUserIDs(state : any, date : string) {
            console.log("fetchAvailableVetsUserIDs");
            console.log(date);
            
            // Remove hyphens from the date string
            const urlSafeDate = date.replace(/-/g, '');

            console.log(process.env.BASE_URL + `api/vets/withavailability/` + urlSafeDate);

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/vets/withavailability/` + urlSafeDate,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((vet_user_ids_data) => {
                        console.log(vet_user_ids_data);
                        console.log(typeof (vet_user_ids_data));
                        state.commit('setAvailableVetsUserIDs', vet_user_ids_data)
                        resolve(vet_user_ids_data)
                    })
                    .catch(err => reject(err))
            }))
        }
    }
}

const editedAvailabilityConfigResetState = () => ({
    editedAvailabilityConfigData:
    {
        id: 0,
        name: '',
        durationMinutes: 20,
        availabilityStep: 30,
        timeAvailableFor: 'P31D',
        subject: 'Wise Vet Live Appointment',
        description: 'Vet appointments',
        location: 'Jitsi video call',
        workHours:
        {
            timezone: 'GMT',
            daySchedules: [],
        },
        context: '',
        startConference: true
    },

    timeLimits: [] as string[],
    /*creatingAvailabilityConfig: false*/
});

export const editedAvailabilityConfig = {
    state: editedAvailabilityConfigResetState(),

    getters: {
        getEditedAvailabilityConfigData(state: any): AvailabilityConfig {
            return (state.editedAvailabilityConfigData)
        },

        getEditedDaySchedules(state: any): DaySchedule[] {
            return (state.editedAvailabilityConfigData.workHours.daySchedules.slice())
        },

        getTimeLimits(state: any): string[] {
            return (state.timeLimits)
        },
    },
    mutations: {

        setEditedAvailabilityConfigData(state: any, availabilityConfigData: any) {

            state.editedAvailabilityConfigData = availabilityConfigData
        },

        setEditedDaySchedules(state: any, editedDaySchedules: any) {
            console.log("setEditedDaySchedules");
            console.log(editedDaySchedules);
            console.log(typeof (editedDaySchedules));
            state.editedAvailabilityConfigData.workHours = Object.assign({}, state.editedAvailabilityConfigData.workHours, { daySchedules: editedDaySchedules })
        },

        setTimeLimits(state: any, newTimeLimits: string[]) {
            state.timeLimits = newTimeLimits
        },
        editedAvailabilityConfigResetStateMut(state: any) {
            Object.assign(state, editedAvailabilityConfigResetState())
        },
        
    },
    actions: {

        async iniEditedAvailabilityConfig(state: any, {vetId, aurinkoProfileId} : {vetId: number, aurinkoProfileId: number})
        {

            try {
                const response = await fetch(process.env.BASE_URL + `api/vets/availability/${vetId}/${aurinkoProfileId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    });

                if (!response.ok) {
                    throw new Error('Failed to fetch availability configuration data');
                }

                const availabilityConfig = await response.json();
                console.log(availabilityConfig);
                state.commit('setEditedAvailabilityConfigData', availabilityConfig);

                return availabilityConfig; // Resolve the promise with the fetched availability configuration data
            } catch (error) {
                console.error('Error fetching availability configuration data:', error);
                throw error; // Re-throw the error to be caught by the caller
            }
        },

        async iniEditedAvailabilityConfigTimeLimits(state: any) {

            console.log("iniEditedAvailabilityConfigTimeLimits")

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/vets/time-limits`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((time_limits) => {
                        console.log(time_limits);
                        state.commit('setTimeLimits', time_limits)
                        resolve(time_limits)
                    })
                    .catch(err => reject(err))
            }))

        },
        updateEditedDaySchedules(state: any, editedDaySchedules: DaySchedule[]) {
            console.log("updateEditedDaySchedules")
            console.log(editedDaySchedules)
            state.commit('setEditedDaySchedules', editedDaySchedules)
        },

        async updateVetAvailabilityProfile(state: any, {vetId, updatedAvailabilityProfile} : {vetId: number, updatedAvailabilityProfile: AvailabilityConfig} )
        {

            const response = await fetch(process.env.BASE_URL + `api/vets/availability/${vetId}/${updatedAvailabilityProfile.id}`,
            {
                method: 'PATCH',
                body: JSON.stringify(updatedAvailabilityProfile),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return response.json
            } else {
                throw new Error('error_updating_availability_profile')
            }
        }
        
    }
}
